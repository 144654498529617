<template>
	<div class="mb-4 mt-5 text-center">
		<h2>Vytvorte si účet</h2>
		<p>alebo sa <a @click="$router.push({name: 'login'})" pointer>prihláste.</a></p>
	</div>
	<form @submit.prevent="register()">
		
		<cmf-input class="input" :icon="person" v-model="v$.form.name" placeholder="Meno a Priezvisko" :vBe="vBe" />
		<cmf-input class="input" :icon="mail" v-model="v$.form.email" placeholder="Email" :vBe="vBe" />
		<cmf-input class="input" :icon="lockClosed" v-model="v$.form.password" placeholder="Heslo" type="password" :vBe="vBe" />

		<ion-button type="submit" expand="block" color="primary" fill="solid" shape="round">Vytvoriť účet</ion-button>
		
	</form>
</template>

<script>
import useVuelidate from '@vuelidate/core'
import { required, email, minLength } from '@vuelidate/validators'
import { person, mail, lockClosed } from 'ionicons/icons'

export default {
	data() {
		return {
			v$: useVuelidate(),
			vBe: [],
			form: {},
			person,
			mail, 
			lockClosed
		}
	},
	validations() {
		return {
			form: {
				name: {required},
				email: {required, email},
				password: {required, minLength: minLength(8)}
			}
		}
	},

	methods: {
		async register() {
			if (!(await this.v$.$validate())) return
			this.$wLoader.startLoading(this.$t('Registering...'))

			try {
				await this.$store.dispatch('wAuth/signup', this.form)

				this.$router.replace({name: 'home'})
			} catch (error) {
				this.$wToast.error(error)
				console.log(error)
				this.vBe = error.response?.data?.error 
			}

			this.$wLoader.clearLoading()
		}
	},

	watch: {
		$route(to) {
			if (to.name == 'register') {
				this.form = {}
				setTimeout(() => { this.v$.$reset() }, 10)
			}
		}
	}
}
</script>